div {
  font-family: "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
}

.App {
  min-height: 90vh;
  padding: 5vh 5vw;
  background-color: #f5f5f5;
  text-align: center;
  color: #223322;
}

.App-header {
  min-height: 5vh;
  background-color: #6fd16c;
  display: flex;
  flex-direction: column;
  justify-content: left;
  color: white;
}

.App-footer {
  min-height: 5vh;
  background-color: #6fd16c74;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}
